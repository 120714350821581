import React from "react"
//import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby";
//import Layout from "../components/layout"
import LayoutHome from "../components/layoutHome"
//import Image from "../components/image"
import { makeStyles} from '@material-ui/core/styles';
//import { Grid, Typography,Paper} from "@material-ui/core";
//import Map from '../components/map'
import Img from "../images/background-min.png"
import play from "../images/play.png"
import {
  Carousel,
  Container,
  Row,
  Col,
  //CardGroup,
  Card,
  CardDeck,
  Button,
  CardColumns

} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { navigate } from "gatsby"
import {Typography,TextField} from '@material-ui/core'
import SubProgram from "../pages/programas/subprogramTemplate"


//console.log(programs)

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(2),
    margin: theme.spacing(1),
    width:"100%",
    //color:"#0000",
    //fontFamily: 'Helvetica Neue',
    //sansSerif, 
    fontSize: "46px", 
    fontWeight: "100", 
    lineHeight: "50px", 
    letterSpacing: "1px", 
    padding: "0 0 40px", 
    //borderBottom: "double #555"
    
    },
    spacingTextDescription:{
      marginBottom: theme.spacing(5),
      marginTop: theme.spacing(5),
    },
    cover:{
      width:"100%",
      height:"100%",
      display:"flex",
      justifyContent: "center",
      alignItems: "center",
      '@media (max-width: 477px)': {
        display:"none"
      },
    },
    GridD:{
      width:"100%",
      height:"95vh",
      '@media (max-width: 477px)': {
        display:"none"
      },
    },
    gridSub:{
      width:"100%",
      height:"100%",
      '@media (max-width: 477px)': {
        display:"none"
      },
    },
    PrincipalImg:{
      //width:"100%",
      height:"100%",
      borderRadius: "8px",
      objectFit: "cover"
    },
    PrincipalText:{
      width:"50%",
      height:"50%",
      //opacity: 0.65,
      //backgroundColor:"red"
    },
    WhoSeccion:{
      width:"100%",
      display:"flex",
      justifyContent: "center",
      alignItems: "center",
      height:"15vh",
      backgroundColor:"#ad1d21",
      color:"white",
      
    },
    WhoSeccionText:{
      width:"90%",
      marginLeft:"5%",
      marginRight:"5%",
      height:"auto",
      color:"black"
    },
    GridWhoWeAre:{
      width:"100%",
      height:"50vh", 
      '@media (max-width: 477px)': {
        display:"none"
      },
    },
    PaperWhoWeAre:{
      width:"100%",
      height:"100%"
    },
    WhoWeAreTtitle:{
      width:"100%",
      height:"20%",
      backgroundColor:"#ad1d21",
      opacity:"0.75",
      color:"white",
      display:"flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom:"10px",
      fontFamily:"Arial Black",
      fontSize:"28px"
    },
    GridLemma:{
      width:"90%",
      height:"50vh",
      marginTop:"5vh",
      marginLeft:"5%",
      //marginRight:"1%"
      '@media (max-width: 477px)': {
        display:"none"
      },
    },
    cta:{
      width:"100%",
      height:"10%",
      display:"flex",
      justifyContent: "center",
      alignItems: "center",
      //opacity: "0.85",
    },
    title:{
      width:"100%",
      justifyContent: "center",
      alignItems: "center",
      display:"flex",
      color:"white"
    },
    maintext:{

    },
    font:{
      color:"white",
      fontFamily:"Montserrat",
      display:"flex",
      justifyContent:"center"
    },
    btnCTA:{
      backgroundColor:"black",
      borderRadius:"28px",
      border:"1px solid #ffffff",
      display:"inline-block",
      cursor:"pointer",
      color:"#ffffff",
      fontSize:"24px",
      padding:"12px 50px",
      textDecoration:"none",
      opacity: "0.85",
    },
    Child:{
      width:"100%",
      height:"60vh"
    },
    Card:{
      width:"100%",
      height:"100%",

    },
    titleW:{
      width:"100%",
      justifyContent: "center",
      alignItems: "start",
      display:"flex",
      fontFamily:"Montserrat",
      padding:"2%"
    },
    ImgPre:{
      width:"60%",
      height:"190px",
      maxWidth:"500px",
      maxHeight:"400px",
      objectFit: "contain"
    },
    WhoWeAreText:{
      fontFamily:"Montserrat",
      fontSize:"14px"
    },
    titleWT:{
      fontFamily:"Arial Black",
      //fontSize:"14px"
    },
   marginleftCard:{
     marginLeft: '2%'
   },
   anuncio:{
     
     '@media (max-width: 477px)': {
      width:"100%",
      height:"100vh",
      backgroundSize: "cover",
      background: `url(${Img}) no-repeat center center`,
    },
   },
   anuncio1:{
     
    '@media (max-width: 477px)': {
     width:"85%",
     height:"33vh",
     backgroundSize: "contain",
     background: `url(${play}) no-repeat center center`,
     marginLeft:"7%",
     display:"flex"
   },
  },
   android:{
     display:"none",
    '@media (max-width: 477px)': {
      display:"block",
      width:"100%",
      height:"100vh",
      position:"fixed"

    },
   },
  banner:{
    width:"100%",
    display:"flex",
    justifyContent:"center",
    alignItems:"flex-start",
    marginTop:"5%"
  },
  link:{
    width:"100%",
    height:"100%",
    textDecoration:"none",
    color:"black" 
  },
  carouselLabels:{
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    background: "rgba(62, 72, 74, 0.5)",
    textAlign:"justify",
    '@media (max-width: 477px)': {
      display:"none"
    },
  },
  titleFont:{
    fontFamily: 'Arial Black !important',
    color: 'white',
    fontWeight: 'bold',
    background: "rgba(62, 72, 74, 0.5)",
    '@media (max-width: 477px)': {
      fontSize:"14px"
    },
  },
  cover:{
    marginTop:"10px",
    paddingBottom:"20px"
  },
  max:{
    borderRadius:"300px"
  },

  BTNbreack:{
    backgroundColor:"transparent",
    borderTop:"transparent",

    '@media (max-width: 570px)': {
      display:"none"
    },
  },
  btn:{
    width:"100%",
    height:"100%",
    color:"white",
    backgroundColor: "#ad1d21",
    borderColor: '#911619',
    fontFamily: 'Montserrat',
    '&:hover': {
      backgroundColor: '#911619',
      borderColor: '#911619',
      boxShadow: 'none',
    },
  },
  justy:{
    textAlign: "justify",
    //whiteSpace: "pre-line"
  }
  
    
}));

const IndexPage = () => {
  const classes = useStyles(); 
  
  const programs= useStaticQuery(graphql`query{
    allRestApiApiPagesShowall {
      edges {
        node {
          data {
            pages {
              id
              content {
                coverPage {
                  ctaText
                  img
                  mainText
                  mainTitle
                  urlCta
                }
                Consultation {
                  description
                  img
                  link
                  title
                }
                Bonus {
                  description
                  img
                  link
                  title
                }
                Org{
                  OrgTitle
                  OrgText
                  OrgImg
                  OrgDoc
              },
                Banner {
                  description
                  img
                  link
                  title
                }
                programs {
                  content
                  description
                  img
                  text
                  title
                  subProgram {
                    content
                    img
                    title
                  }
                }
                whoWeAre {
                  description
                  img
                  lemma {
                    mision {
                      content
                      img
                      title
                    }
                    values {
                      content
                      img
                      title
                    }
                    vision {
                      content
                      img
                      title
                    }
                  }
                  title
                }
              }
            }
          }
        }
      }
    }
    allRestApiApiCarouselGet {
      edges {
        node {
          data {
            img 
            text
            title
            url
          }
        }
      }
    }
  }`)

  const handleClick= link => () => {
    navigate(link)
  }
const openWin = () => {
  window.open("https://www.w3schools.com");
}

  const call = programs.allRestApiApiPagesShowall.edges[0].node.data.pages[0].content
  const callCarousel = programs.allRestApiApiCarouselGet.edges[0].node.data
  const consulta = programs.allRestApiApiPagesShowall.edges[0].node.data.pages[0].content.Consultation
  const Beneficios = programs.allRestApiApiPagesShowall.edges[0].node.data.pages[0].content.Bonus
  const Programas = programs.allRestApiApiPagesShowall.edges[0].node.data.pages[0].content.Programs
  const Banner = programs.allRestApiApiPagesShowall.edges[0].node.data.pages[0].content.Banner
  const Org = programs.allRestApiApiPagesShowall.edges[0].node.data.pages[0].content.Org
  console.log(Org)
  return(
    <LayoutHome>
  
      <Container  className={classes.cover}>
          <Row fluid>
            <Col fluid>
            <Carousel  fluid interval={4000} >
            {
              callCarousel.map(item=>{
                return(
                  <Carousel.Item >
                    <a href={item.url} className={classes.max}>
                    <img
                      //className="d-block w-100"
                      src={item.img}
                      //src={"https://picsum.photos/1080/400"}
                      alt={item.title}
                  /></a>
                  <Carousel.Caption className={classes.ca}>
                    <h3 className={classes.carouselLabels, classes.titleFont}>{item.title}</h3>
                    <p className={classes.carouselLabels}>
                      {item.text}
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
                )
              })
            }
            
          </Carousel>
            </Col>
          </Row>
      </Container>
      
      <Container sm={12} md={12} lg={10} xl={10}>
         <Card>
            <Card.Body>
            <Card.Title className={classes.WhoWeAreTtitle}>{call.whoWeAre.title}</Card.Title>
              <Card.Text className={classes.justy} >
                {call.whoWeAre.description}
              </Card.Text>
            </Card.Body>
            <Card.Img variant="top" src={call.whoWeAre.img}  />
          </Card>
         </Container>

         <Container sm={12} md={12} lg={10} xl={10}>
           <Typography className={classes.WhoWeAreTtitle}>"POR LA UNIDAD EN EL TRABAJO SINDICAL"</Typography>
         </Container>

      <Container sm={12} md={12} lg={12} xl={12}>
      <CardDeck>
    <Card>
    <Card.Img variant="top" src={call.whoWeAre.lemma.mision.img}  />
    <Card.Body>
      <Card.Title>{call.whoWeAre.lemma.mision.title}</Card.Title>
      <Card.Text className={classes.justy}>
      {call.whoWeAre.lemma.mision.content}
      </Card.Text>
    </Card.Body>
  </Card>
  <Card>
    <Card.Img variant="top" src={call.whoWeAre.lemma.vision.img}  />
    <Card.Body>
      <Card.Title>{call.whoWeAre.lemma.vision.title}</Card.Title>
      <Card.Text className={classes.justy}>
      {call.whoWeAre.lemma.vision.content}
      </Card.Text>
    </Card.Body>
  </Card>
  <Card>
    <Card.Img variant="top" src={call.whoWeAre.lemma.values.img}  />
    <Card.Body>
      <Card.Title>{call.whoWeAre.lemma.values.title}</Card.Title>
      <Card.Text className={classes.justy}>
      {call.whoWeAre.lemma.values.content}
      </Card.Text>
    </Card.Body>
  </Card>
</CardDeck>


      </Container>
  
      <Container sm={12} md={12} lg={10} xl={10}>
      <a onClick={handleClick('/descuentos')} href="#"  className={classes.max}>
    <Card className="text-white">
        <Card.Img src={Beneficios.img} alt="Card image" />
          <Card.ImgOverlay>
          <Card.Title>{Beneficios.title}</Card.Title>
              <Card.Text>
              {Beneficios.description} 
              </Card.Text>
          </Card.ImgOverlay>
         
          </Card>
          </a>
      </Container> 
        {
        //Seccion de Beneficios
        }
      <Container sm={12} md={12} lg={9} xl={9}>
      <a onClick={handleClick('/tickets')} href="#"  className={classes.max}>
    <Card className="text-white">
        <Card.Img src={consulta.img} alt="Card image" />
          <Card.ImgOverlay>
            <Card.Title>{consulta.title}</Card.Title>
              <Card.Text>
              {consulta.description}
              </Card.Text>
          </Card.ImgOverlay>
          </Card>
          </a>


      </Container>
      
                
        {
        //Seccion de programas
        }
      <Container sm={12} md={12} lg={10} xl={10}>
      <CardColumns>
   {/* <Card>
    <Card.Img variant="top" src={Programas[0].img} />
    <Card.Body>
      <Card.Title>{Programas[0].title}</Card.Title>
      <Card.Text>
      {Programas[0].text}
      </Card.Text>
    </Card.Body>
    <Card.Footer>
           <Button variant="primary" onClick={handleClick('/programas/Programas-de-Apoyo-a-la-Economía-Familiar')} >Mas Informacion</Button>
          </Card.Footer>
  </Card>
  <Card>
    <Card.Img variant="top" src={Programas[1].img} />
    <Card.Body>
      <Card.Title>{Programas[1].title}</Card.Title>
      <Card.Text>
      {Programas[1].text}
      </Card.Text>
    </Card.Body>
    <Card.Footer>
           <Button variant="primary" onClick={handleClick('/programas/Promoción-de-Actividades-Deportivas,-Culturales-y-Recreativas')} >Mas Informacion</Button>
          </Card.Footer>
  </Card>
  <Card>
    <Card.Img variant="top" src={Programas[2].img} />
    <Card.Body>
    <Card.Title>{Programas[2].title}</Card.Title>
      <Card.Text>
      {Programas[2].text}
      </Card.Text>
    </Card.Body>
    <Card.Footer>
           <Button variant="primary" onClick={handleClick('/programas/Bienestar-Social-Integral')} >Mas Informacion</Button>
          </Card.Footer>
  </Card>

  <Card>
    <Card.Img variant="top" src={Programas[3].img} />
    <Card.Body>
    <Card.Title>{Programas[3].title}</Card.Title>
      <Card.Text>
      {Programas[3].text}
      </Card.Text>
    </Card.Body>
    <Card.Footer>
           <Button variant="primary" onClick={handleClick('/programas/Educación-Sindical-y-Formación-Laboral')} >Mas Informacion</Button>
          </Card.Footer>
  </Card>

  <Card>
  <Card.Img variant="top" src={Programas[4].img} />
    <Card.Body>
    <Card.Title>{Programas[4].title}</Card.Title>
      <Card.Text>
      {Programas[4].text}
      </Card.Text>
    </Card.Body>
    <Card.Footer>
           <Button variant="primary" onClick={handleClick('/programas/Comunicaci%C3%B3n-Efectiva-Permanente')} >Mas Informacion</Button>
          </Card.Footer>
  </Card>
   */}



      { 
       programs.allRestApiApiPagesShowall.edges[0].node.data.pages[12].content.programs.map( subProgram => {
         return( 
          <SubProgram subProgram={subProgram} />
          )
       })}

<Card>
    <Card.Img variant="top" src={Org.OrgImg} />
    <Card.Body>
      <Card.Title>{Org.OrgTitle}</Card.Title>
      <Card.Text>
      {Org.OrgText}
      </Card.Text>
    </Card.Body>
    <Card.Footer>
           <Button href={Org.OrgDoc} className={classes.btn}>Descarga</Button>
    </Card.Footer>
  </Card>

</CardColumns>

</Container>
      

<Container sm={12} md={12} lg={9} xl={9}>
<a href="https://play.google.com/store/apps/details?id=com.sitimm.app" className={classes.max}>
    <Card className="text-white">
      
        <Card.Img src={Banner.img} alt="Card image" />
          <Card.ImgOverlay>
            <Card.Title>{Banner.title}</Card.Title>
              <Card.Text>
              {Banner.description}
              </Card.Text>
          </Card.ImgOverlay>
          </Card>
          </a>
      </Container>
  </LayoutHome>
  )
}

export default IndexPage
